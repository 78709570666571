<template>
    <div class="home">
      <br>
      <BannerCom />
      <br><br><br><br><br><br>
      <FooterCom />
    </div>
</template>

<script>
import BannerCom from "./BannerCom";
import FooterCom from "@/components/FooterCom";

export default {
  name: "Home",
  components:{
    BannerCom,
    FooterCom,
  },
}
</script>

<style scoped>

</style>