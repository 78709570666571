<template>
    <div class="joke">
        <div class="main">
            <!-- 筛选条件 -->
            <div class="condition">
                <ul class="cate-list">
                    <li class="title">段子分类:</li>
                    <li :class="filter.joke_category==0?'this':''" @click="filter.joke_category=0">查看</li>
                    <li :class="filter.joke_category==category.id?'this':''" v-for="category in category_list"
                        @click="filter.joke_category=category.id" :key="category.name">{{category.name}}
                    </li>
                </ul>

                <div class="ordering">
                    <ul>
                        <li class="title">筛&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;选:</li>
                        <li class="default" :class="(filter.ordering=='id' || filter.ordering=='-id')?'this':''"
                            @click="filter.ordering='-id'">默认
                        </li>
                        <li class="hot" :class="(filter.ordering=='students' || filter.ordering=='-students')?'this':''"
                            @click="filter.ordering=(filter.ordering=='-students'?'students':'-students')">人气
                        </li>
<!--                        <li class="price"-->
<!--                            :class="filter.ordering=='price'?'price_up this':(filter.ordering=='-price'?'price_down this':'')"-->
<!--                            @click="filter.ordering=(filter.ordering=='-price'?'price':'-price')">价格-->
<!--                        </li>-->
                    </ul>
                    <p class="condition-result">共{{joke_total}}个段子</p>
                </div>

            </div>
            
            <h1>笑话段子</h1>
            
            <!-- 课程列表 -->
            <div class="joke-list">
                <div class="joke-item" v-for="joke in joke_list" :key="joke.name">
                    <div class="joke-image">
                        <img :src="joke.joke_img" alt="">
                    </div>
                    <div class="joke-info">
                        <h3>
                            <router-link :to="'/free/detail/'+joke.id">{{joke.name}}</router-link>
                            <span><img src="@/assets/img/avatar1.svg" alt="">{{joke.students}}人已加入学习</span></h3>
                        <p class="teather-info">
                            {{joke.teacher.name}} {{joke.teacher.title}} {{joke.teacher.signature}}
                            <span v-if="joke.sections>joke.pub_sections">共{{joke.sections}}课时/已更新{{joke.pub_sections}}课时</span>
                            <span v-else>共{{joke.sections}}课时/更新完成</span>
                        </p>
                        <ul class="section-list">
                            <li v-for="(section, key) in joke.section_list" :key="section.name"><span
                                    class="section-title">0{{key+1}}  |  {{section.name}}</span>
                                <span class="free" v-if="section.free_trail">免费</span></li>
                        </ul>
                        <div class="pay-box">
                            <div v-if="joke.discount_type">
                                <span class="discount-type">{{joke.discount_type}}</span>
                                <span class="discount-price">￥{{joke.real_price}}元</span>
                                <span class="original-price">原价：{{joke.price}}元</span>
                            </div>
                            <span v-else class="discount-price">￥{{joke.price}}元</span>
                            <span class="buy-now" @click="buy_joke(joke)">立即购买</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="joke_pagination block">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="filter.page"
                        :page-sizes="[2, 3, 5, 10]"
                        :page-size="filter.page_size"
                        layout="sizes, prev, pager, next"
                        :total="joke_total">
                </el-pagination>
            </div>
        </div>
        <FooterCom></FooterCom>
    </div>
</template>

<script>
import FooterCom from "@/components/FooterCom";

export default {
    name: "Lightjoke",
    components: {
        FooterCom,
    },
    data() {
        return {
            category_list: [], // 游戏分类列表
            joke_list: [],   // 游戏列表
            joke_total: 0,   // 当前游戏<的总数量
            filter: {
                joke_category: 0, // 当前用户选择的游戏分类，刚进入页面默认为全部，值为0
                ordering: "-id",    // 数据的排序方式，默认值是-id，表示对于id进行降序排列
                page_size: 2,       // 单页数据量
                page: 1,
            }
        }
    },
    created() {
        this.get_category();
        this.get_joke();
    },
    watch: {
        "filter.joke_category"(){
            this.filter.page = 1;
            this.get_joke();
        },
        "filter.ordering"(){
            this.get_joke();
        },
        "filter.page_size"(){
            this.get_joke();
        },
        "filter.page"(){
            this.get_joke();
        }
    },
    methods: {
        handleSizeChange(val) {
            // 每页数据量发生变化时执行的方法
            this.filter.page = 1;
            this.filter.page_size = val;
        },
        handleCurrentChange(val) {
            // 页码发生变化时执行的方法
            this.filter.page = val;
        },
        get_category() {
            // 获取课程分类信息
            this.$axios.get(this.$settings.BASE_URL + 'pc/joke/category/').then(response => {
                this.category_list = response.data.result;
            }).catch(() => {
                this.$message({
                    message: "获取游戏分类信息有误，请联系客服工作人员",
                })
            })
        },
        get_joke() {

        }
    },
}
</script>

<style scoped>
    .joke {
        background: #f6f6f6;
    }

    .joke .main {
        padding-top: 35px;
        width: 1100px;
        margin: 0 auto;
    }

    .joke .condition {
        margin-bottom: 35px;
        padding: 25px 30px 25px 20px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 #f0f0f0;
    }

    .joke .cate-list {
        border-bottom: 1px solid #333;
        border-bottom-color: rgba(51, 51, 51, .05);
        padding-bottom: 18px;
        margin-bottom: 17px;
    }

    .joke .cate-list::after {
        content: "";
        display: block;
        clear: both;
    }

    .joke .cate-list li {
        float: left;
        font-size: 16px;
        padding: 6px 15px;
        line-height: 16px;
        margin-left: 14px;
        position: relative;
        transition: all .3s ease;
        cursor: pointer;
        color: #4a4a4a;
        border: 1px solid transparent; /* transparent 透明 */
    }

    .joke .cate-list .title {
        color: #888;
        margin-left: 0;
        letter-spacing: .36px;
        padding: 0;
        line-height: 28px;
    }

    .joke .cate-list .this {
        color: #ffc210;
        border: 1px solid #ffc210 !important;
        border-radius: 30px;
    }

    .joke .ordering::after {
        content: "";
        display: block;
        clear: both;
    }

    .joke .ordering ul {
        float: left;
    }

    .joke .ordering ul::after {
        content: "";
        display: block;
        clear: both;
    }

    .joke .ordering .condition-result {
        float: right;
        font-size: 14px;
        color: #9b9b9b;
        line-height: 28px;
    }

    .joke .ordering ul li {
        float: left;
        padding: 6px 15px;
        line-height: 16px;
        margin-left: 14px;
        position: relative;
        transition: all .3s ease;
        cursor: pointer;
        color: #4a4a4a;
    }

    .joke .ordering .title {
        font-size: 16px;
        color: #888;
        letter-spacing: .36px;
        margin-left: 0;
        padding: 0;
        line-height: 28px;
    }

    .joke .ordering .this {
        color: #ffc210;
    }

    .joke .ordering .price {
        position: relative;
    }

    .joke .ordering .price::before,
    .joke .ordering .price::after {
        cursor: pointer;
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        border: 5px solid transparent;
        position: absolute;
        right: 0;
    }

    .joke .ordering .price::before {
        border-bottom: 5px solid #aaa;
        margin-bottom: 2px;
        top: 2px;
    }

    .joke .ordering .price::after {
        border-top: 5px solid #aaa;
        bottom: 2px;
    }

    .joke .ordering .price_up::before {
        border-bottom-color: #ffc210;
    }

    .joke .ordering .price_down::after {
        border-top-color: #ffc210;
    }

    .joke .joke-item:hover {
        box-shadow: 4px 6px 16px rgba(0, 0, 0, .5);
    }

    .joke .joke-item {
        width: 1100px;
        background: #fff;
        padding: 20px 30px 20px 20px;
        margin-bottom: 35px;
        border-radius: 2px;
        cursor: pointer;
        box-shadow: 2px 3px 16px rgba(0, 0, 0, .1);
        /* css3.0 过渡动画 hover 事件操作 */
        transition: all .2s ease;
    }

    .joke .joke-item::after {
        content: "";
        display: block;
        clear: both;
    }

    /* 顶级元素 父级元素  当前元素{} */
    .joke .joke-item .joke-image {
        float: left;
        width: 423px;
        height: 210px;
        margin-right: 30px;
    }

    .joke .joke-item .joke-image img {
        max-width: 100%;
        max-height: 210px;
    }

    .joke .joke-item .joke-info {
        float: left;
        width: 596px;
    }

    .joke-item .joke-info h3 a {
        font-size: 26px;
        color: #333;
        font-weight: normal;
        margin-bottom: 8px;
    }

    .joke-item .joke-info h3 span {
        font-size: 14px;
        color: #9b9b9b;
        float: right;
        margin-top: 14px;
    }

    .joke-item .joke-info h3 span img {
        width: 11px;
        height: auto;
        margin-right: 7px;
    }

    .joke-item .joke-info .teather-info {
        font-size: 14px;
        color: #9b9b9b;
        margin-bottom: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid #333;
        border-bottom-color: rgba(51, 51, 51, .05);
    }

    .joke-item .joke-info .teather-info span {
        float: right;
    }

    .joke-item .section-list::after {
        content: "";
        display: block;
        clear: both;
    }

    .joke-item .section-list li {
        float: left;
        width: 44%;
        font-size: 14px;
        color: #666;
        padding-left: 22px;
        /* background: url("路径") 是否平铺 x轴位置 y轴位置 */
        background: url("/src/assets/img/play-icon-gray.svg") no-repeat left 4px;
        margin-bottom: 15px;
    }

    .joke-item .section-list li .section-title {
        /* 以下3句，文本内容过多，会自动隐藏，并显示省略符号 */
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        max-width: 200px;
    }

    .joke-item .section-list li:hover {
        background-image: url("/src/assets/img/play-icon-yellow.svg");
        color: #ffc210;
    }

    .joke-item .section-list li .free {
        width: 34px;
        height: 20px;
        color: #fd7b4d;
        vertical-align: super;
        margin-left: 10px;
        border: 1px solid #fd7b4d;
        border-radius: 2px;
        text-align: center;
        font-size: 13px;
        white-space: nowrap;
    }

    .joke-item .section-list li:hover .free {
        color: #ffc210;
        border-color: #ffc210;
    }

    .joke-item {
        position: relative;
    }

    .joke-item .pay-box {
        position: absolute;
        bottom: 20px;
        width: 600px;
    }

    .joke-item .pay-box::after {
        content: "";
        display: block;
        clear: both;
    }

    .joke-item .pay-box .discount-type {
        padding: 6px 10px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        margin-right: 8px;
        background: #fa6240;
        border: 1px solid #fa6240;
        border-radius: 10px 0 10px 0;
        float: left;
    }

    .joke-item .pay-box .discount-price {
        font-size: 24px;
        color: #fa6240;
        float: left;
    }

    .joke-item .pay-box .original-price {
        text-decoration: line-through;
        font-size: 14px;
        color: #9b9b9b;
        margin-left: 10px;
        float: left;
        margin-top: 10px;
    }

    .joke-item .pay-box .buy-now {
        width: 120px;
        height: 38px;
        background: transparent;
        color: #fa6240;
        font-size: 16px;
        border: 1px solid #fd7b4d;
        border-radius: 3px;
        transition: all .2s ease-in-out;
        float: right;
        text-align: center;
        line-height: 38px;
        position: absolute;
        right: 0;
        bottom: 5px;
    }

    .joke-item .pay-box .buy-now:hover {
        color: #fff;
        background: #ffc210;
        border: 1px solid #ffc210;
    }

    .joke .joke_pagination {
        margin-bottom: 60px;
        text-align: center;
    }
</style>

