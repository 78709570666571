<template>
<div class="header">
    <div class="slogan">
        <p>选我TOP | 多元文化展示</p>
    </div>
    <div class="nav">
        <ul class="left-part">
            <li class="logo">
                <span @click="goPage('/home')" :class="{active: url_path === '/'}">
                    <img height="48" src="../../public/logo.png" alt="">
                </span>
            </li>
            <li class="ele">
                <span @click="goPage('/fire')" :class="{active: url_path === '/fire'}">烟花</span>
            </li>
            <li class="ele">
                <span @click="goPage('/love')" :class="{active: url_path === '/love'}">表白树</span>
            </li>
<!--            <li class="ele">-->
<!--                <span @click="goPage('/handtrack')" :class="{active: url_path === '/love'}">手势</span>-->
<!--            </li>-->
<!--            <li class="ele">-->
<!--                <span @click="goPage('/news')" :class="{active: url_path === '/news'}">新闻</span>-->
<!--            </li>-->
<!--            <li class="ele">-->
<!--                <span @click="goPage('/joke')" :class="{active: url_path === '/joke'}">段子</span>-->
<!--            </li>-->
<!--            <li class="ele">-->
<!--                <span @click="goPage('/note')" :class="{active: url_path === '/note'}">程序笔记</span>-->
<!--            </li>-->
<!--            <li class="ele">-->
<!--                <span @click="goPage('/game')" :class="{active: url_path === '/game'}">游戏城</span>-->
<!--            </li>-->
<!--            <li class="ele">-->
<!--                <span @click="goPage('/contact')" :class="{active: url_path === '/contact'}">联系我们</span>-->
<!--            </li>-->
        </ul>
        <div class="right-part">
            <div v-if="name">
                <span >{{name}}</span>
                <span class="line">|</span>
                <span @click="logout">注销</span>
            </div>
            <div v-else>
                <span @click="put_login">登录</span>
                <span class="line">|</span>
                <span @click="put_register">注册</span>
            </div>
        </div>
        <LoginCom v-if="is_login" @close="close_login" @login_success="login_success" @go="put_register" />
        <RegisterCom v-if="is_register" @close="close_register" @register_success="register_success" @go="put_login" />

        <form class="search" @submit.prevent>
            <div class="tips" v-if="is_search_tip">
                <span @click="search_action('Python')">Python</span>
                <span @click="search_action('Linux')">Linux</span>
            </div>
            <input type="text" :placeholder="search_placeholder" @focus="on_search" @blur="off_search" v-model="search_word" @keydown.enter="search_action(search_word)">
            <button type="button" class="glyphicon glyphicon-search" @click="search_action(search_word)"></button>
        </form>
    </div>
</div>
</template>

<script>
import LoginCom from "@/components/LoginCom";
import RegisterCom from "@/components/RegisterCom";

export default {
    name: "HeaderCom",
    components: {LoginCom, RegisterCom},
    data() {
        return {
            url_path: sessionStorage.url_path || '/',
            is_login: false,
            is_register: false,
            access: '',
            name: '',
            is_search_tip: true,
            search_placeholder: '',
            search_word: ''
        }
    },
    methods: {
        goPage(url_path) {
            // 已经是当前路由就没有必要重新跳转
            if (this.url_path !== url_path) {
                this.$router.push(url_path);
                sessionStorage.url_path = this.url_path = url_path;
            }
        },
        put_login(){
            this.is_login = true;
        },
        put_register(){
            this.is_register = true;
        },
        close_login(){
            this.is_login = false;
        },
        close_register(){
            this.is_register = false;
        },
        login_success(access,name){
            this.access = access
            this.name = name
        },
        register_success(access,name){
            this.access = access
            this.name = name
        },
        logout(){
            this.$cookies.remove('access')
            this.$cookies.remove('name')
            this.access = ''
            this.name = ''
        },
        search_action(search_word) {
            if (!search_word) {
                this.$message('请输入要搜索的内容');
                return
            }
            if (search_word !== this.$route.query.word) {
                this.$router.push(`/course/search/?word=${search_word}`);
            }
            this.search_word = '';
        },
        on_search() {
            this.search_placeholder = '请输入想搜索的课程';
            this.is_search_tip = false;
        },
        off_search() {
            this.search_placeholder = '';
            this.is_search_tip = true;
        },
    },
    created() {
        sessionStorage.url_path = this.$route.path;
        this.url_path = this.$route.path;

        // 登录的时候取access和name值
        this.access = this.$cookies.get('access')
        this.name = this.$cookies.get('name')
    }
}
</script>

<style scoped>
    .header {
        background-color: white;
        box-shadow: 0 0 5px 0 #aaa;
    }

    .header:after {
        content: "";
        display: block;
        clear: both;
    }

    .slogan {
        background-color: #eee;
        height: 40px;
    }

    .slogan p {
        width: 1200px;
        margin: 0 auto;
        color: #aaa;
        font-size: 13px;
        line-height: 40px;
    }

    .nav {
        background-color: white;
        user-select: none;
        width: 1200px;
        margin: 0 auto;

    }

    .nav ul {
        float: left;
        margin-bottom: 0;
    }

    .nav ul li{
        padding: 15px 0;
    }

    .nav ul:after {
        clear: both;
        content: '';
        display: block;
    }

    .nav ul li {
        float: left;
    }

    .nav ul li.logo {
        margin-right: 20px;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .ele {
        margin: 0 20px;
    }

    .ele span {
        display: block;
        font: 15px/36px '微软雅黑';
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }

    .ele span:hover {
        border-bottom-color: orange;
    }

    .ele span.active {
        color: orange;
        border-bottom-color: orange;
    }

    .right-part {
        float: right;
    }

    .right-part .line {
        margin: 0 10px;
    }

    .right-part span {
        line-height: 68px;
        cursor: pointer;
    }

    /*搜索框*/
    .search {
        float: right;
        position: relative;
        margin-top: 22px;
        margin-right: 10px;
    }

    .search input, .search button {
        border: none;
        outline: none;
        background-color: white;
    }

    .search input {
        border-bottom: 1px solid #eeeeee;
    }

    .search input:focus {
        border-bottom-color: orange;
    }

    .search input:focus + button {
        color: orange;
    }

    .search .tips {
        position: absolute;
        bottom: 3px;
        left: 0;
    }

    .search .tips span {
        border-radius: 11px;
        background-color: #eee;
        line-height: 22px;
        display: inline-block;
        padding: 0 7px;
        margin-right: 3px;
        cursor: pointer;
        color: #aaa;
        font-size: 14px;

    }

    .search .tips span:hover {
        color: orange;
    }
</style>


