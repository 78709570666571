import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home/Home';
import News from "@/views/news/News";
import Jokes from "@/views/joke/Joke";
import Notes from "@/views/note/Note";
import Games from "@/views/game/Game";
import Contact from "@/views/contact/Contact";
import Fire from "@/views/fire/Fire";
import Love from "@/views/love/Love";
import HandTrack from "@/views/handTrack/HandTrack";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/home',
        redirect: '/',
    },
    {
        path: '/fire',
        name: 'fire',
        component: Fire
    },
    {
        path: '/love',
        name: 'love',
        component: Love
    },
    // {
    //     path: '/handtrack',
    //     name: 'handtrack',
    //     component: HandTrack
    // },
    // {
    //     path: '/news',
    //     name: 'news',
    //     component: News,
    // },
    // {
    //     path: '/joke',
    //     name: 'joke',
    //     component: Jokes,
    // },
    // {
    //     path: '/note',
    //     name: 'note',
    //     component: Notes,
    // },
    // {
    //     path: '/game',
    //     name: 'game',
    //     component: Games,
    // },
    // {
    //     path: '/contact',
    //     name: 'contact',
    //     component: Contact,
    // },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;


