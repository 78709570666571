<template>
  <div id="app">
      <HeaderCom class="header"></HeaderCom>
      <router-view></router-view>
  </div>
</template>

<script>
import HeaderCom from "@/components/HeaderCom";

export default {
  components:{
    HeaderCom,
  },
}
</script>

<style>

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    min-height: 100%;
    line-height: 1.42857143;
    font-size: 14px;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff!important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

</style>


