import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import cookie from 'vue-cookies';

Vue.use(cookie);
Vue.use(ElementUI);

require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
import VideoPlayer from 'vue-video-player';

Vue.use(VideoPlayer);

import '@/assets/css/global.css';
import settings from '@/assets/js/settings';
import axios from 'axios';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

Vue.prototype.$settings = settings;
Vue.prototype.$axios = axios;

import {Base64} from 'js-base64';
Vue.use(Base64);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
