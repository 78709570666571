<template>
    <h1>联系我们</h1>
</template>

<script>
export default {
    name: "Contact"
}
</script>

<style scoped>

</style>