<template>
    <div>
        <iframe src="love.html" id="love" name="love" style="width: 100%;height: 800px" ref="love"></iframe>
    </div>
</template>

<script>
    export default {
        name: "Love"
    }
</script>

<style scoped>

</style>