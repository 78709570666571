<template>
    <div>
        <video id="video"></video>
        <canvas id="canvas"></canvas>
    </div>
</template>

<script>
    // import * as handTrack from 'handtrackjs';
export default {
    name: "HandTrack",
    components: {

    },
    data() {
        return {
            category_list: [], // 游戏分类列表
            game_list: [],   // 游戏列表
            game_total: 0,   // 当前游戏<的总数量
            filter: {
                game_category: 0, // 当前用户选择的游戏分类，刚进入页面默认为全部，值为0
                ordering: "-id",    // 数据的排序方式，默认值是-id，表示对于id进行降序排列
                page_size: 2,       // 单页数据量
                page: 1,
            }
        }
    },
    created() {
        navigator.mediaDevices.getUserMedia =
            navigator.mediaDevices.getUserMedia ||
            navigator.mediaDevices ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia;
        const video = document.getElementById('video')
        const canvas = document.getElementById('canvas')
        const context = document.getContext('2d');
        let model;

        function handDetection() {
            model.detect(video).then(predictions => {
                // model.renderPredictions(predictions, canvas, context, video)
                predictions.forEach(item => {
                    if (item.label === "open") {

                    }
                })
            })
        }

        function startVideo() {
            handTrack.startVideo(video).then((status = {}) => {
                if (status.status) {
                    navigator.mediaDevices.getUserMedia(
                        {video: true}
                    ).then(stream => {
                        video.srcObj = stream;
                        handDetection();
                    })
                }
            })
        }
    },
    watch: {
        "filter.game_category"(){
            this.filter.page = 1;
            this.get_game();
        },
    },
    methods: {
        get_category() {
            // 获取课程分类信息
            this.$axios.get(this.$settings.BASE_URL + 'pc/game/category/').then(response => {
                this.category_list = response.data.result;
            }).catch(() => {
                this.$message({
                    message: "获取游戏分类信息有误，请联系客服工作人员",
                })
            })
        },
    },
}
</script>

<style scoped>

</style>