<template>
    <div class="banner">
        <el-carousel height="400px">
            <el-carousel-item v-for="item in bannerArr" :key="item.id">
<!--                <img :src="item.url" alt="item.name" @click="goPage(item.link)">-->
                <img src="../../assets/img/banner.png" :alt="item.name">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>

export default {
    name: "BannerCom",
    data() {
        return {
            url_path: sessionStorage.url_path || '/',
            bannerArr: [{"id": 1, "name": "Banner图"}]
        }
    },
    methods: {
        goPage(url_path) {
            if (url_path !== null){
                if (this.url_path !== url_path) {
                    this.$router.push(url_path);
                    sessionStorage.url_path = url_path;
                }
            }
        },
    },
    // created() {
    //     this.$axios.get(this.$settings.BASE_URL + 'pc/home/banner/').then((response) => {
    //         this.bannerArr = response.data.result
    //     }).catch((errors) => {
    //         console.log(errors)
    //     })
    // }
}
</script>

<style scoped>
.banner {
    height: 400px;
    min-width: 800px;
}

.banner img {
    height: 400px;
    margin-left: calc(50% - 1920px / 2 - 160px);
}
</style>
