<template>
    <div class="login">
        <div class="box">
            <i class="el-icon-close" @click="close_login"></i>
            <div class="content">
                <div class="nav">
                    <span :class="{active: login_method === 'is_pwd'}"
                          @click="change_login_method('is_pwd')">密码登录</span>
                    <span :class="{active: login_method === 'is_sms'}"
                          @click="change_login_method('is_sms')">短信登录</span>
                </div>
                <el-form v-if="login_method === 'is_pwd'">
                    <el-input
                            placeholder="用户名/手机号/邮箱"
                            prefix-icon="el-icon-user"
                            v-model="username"
                            clearable>
                    </el-input>
                    <el-input
                            placeholder="密码"
                            prefix-icon="el-icon-key"
                            v-model="password"
                            clearable
                            show-password>
                    </el-input>
                    <el-button type="primary" @click="passwordLogin">登录</el-button>
                </el-form>
                <el-form v-if="login_method === 'is_sms'">
                    <el-input
                            placeholder="手机号"
                            prefix-icon="el-icon-phone-outline"
                            v-model="phone"
                            clearable
                            @blur="check_phone">
                    </el-input>
                    <el-input
                            placeholder="验证码"
                            prefix-icon="el-icon-chat-line-round"
                            v-model="sms"
                            clearable>
                        <template slot="append">
                            <span class="sms" @click="send_sms">{{ sms_interval }}</span>
                        </template>
                    </el-input>
                    <el-button type="primary" @click="codeLogin">登录</el-button>
                </el-form>
                <div class="foot">
                    <span @click="go_register">立即注册</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "LoginCom",
  data() {
      return {
          username: '',
          password: '',
          phone: '',
          sms: '',
          login_method: 'is_pwd',
          sms_interval: '获取验证码',
          phone_is_true: false,
          is_sending: false,
      }
  },
  methods: {
      close_login() {
          this.$emit('close')
      },
      go_register() {
          this.$emit('close')
          this.$emit('go')
      },
      change_login_method(method) {
          this.login_method = method;
      },
      check_phone() {
          if (!this.phone) return;
          if (!this.phone.match(/^1[3-9][0-9]{9}$/)) {
              this.$message({
                  message: '手机号有误',
                  type: 'warning',
                  duration: 1,
                  // onClose: () => {
                  //     this.phone = '';
                  // }
              });
              this.phone_is_true = false;
              return;
          }
          this.phone_is_true = true;
      },
      send_sms() {
          if (!this.phone_is_true){
              this.$message({
                  message: '手机号有误',
                  type: 'warning',
                  duration: 1,
              });
              return;
          }
          if (this.is_sending){
              this.$message({
                  message: this.sms_interval,
                  type: 'warning',
                  duration: 1,
              });
              return;
          }
          this.$axios({
              method: 'get',
              baseURL: this.$settings.BASE_URL,
              url: 'user/get_code/',
              params: {
                  'phone': this.phone,
                  'to': '1',
              },
          }).then(response => {
              if(response.data.code === 1){
                  this.$message({
                      message: response.data.result,
                      type: 'success',
                      duration: 6000,
                  })
                  this.phone_is_true = false;
                  let sms_interval_time = 60;
                  this.sms_interval = "发送中...";
                  this.is_sending = true
                  let timer = setInterval(() => {
                      if (sms_interval_time <= 1) {
                          clearInterval(timer);
                          this.sms_interval = "获取验证码";
                          this.phone_is_true = true; // 重新回复点击发送功能的条件
                          this.is_sending = false
                      } else {
                          sms_interval_time -= 1;
                          this.sms_interval = `${sms_interval_time}秒后再发`;
                      }
                  }, 1000);
              }else{
                  this.$message({
                  message: response.data.msg,
                  type: 'warning',
                  duration: 1000,
              })}
          }).catch(errors => {
              this.$message({
                  message: errors.message,
                  type: 'warning',
                  duration: 1000,
              })
          })
      },
      passwordLogin(){
          if(this.username&&this.password){
              this.$axios.post(this.$settings.BASE_URL + 'user/login/', {
                  username: this.username,
                  password: this.password,
              }).then(response => {
                  if(response.data.code === 1){
                      this.$cookies.set('access', response.data.access, '7d')
                      this.$cookies.set('name', response.data.name, '7d')
                      this.$emit('close')
                      this.$emit('login_success',response.data.access,response.data.name)
                  }else{
                      this.$message({
                          showClose: true,
                          message: response.data.result,
                          type: 'error'
                      });
                  }
              }).catch(errors => {
                  this.$message({
                      showClose: true,
                      message: errors.message,
                      type: 'error'
                  });
              })
          }else{
              this.$message({
                  showClose: true,
                  message: '账号或者密码不能为空',
                  type: 'error'
              });
          }
      },
      codeLogin(){
          if(this.phone&&this.sms){
              this.$axios.post(this.$settings.BASE_URL + 'user/code_login/', {
                  phone: this.phone,
                  code: this.sms,
              }).then(response => {
                  console.log(response.data)
                  if(response.data.code === 1){
                      this.$cookies.set('access', response.data.access, '7d')
                      this.$cookies.set('name', response.data.name, '7d')
                      this.$emit('close')
                      this.$emit('login_success',response.data.access,response.data.name)
                  }else{
                      this.$message({
                          showClose: true,
                          message: response.data.result,
                          type: 'error'
                      });
                  }
              }).catch(errors => {
                  this.$message({
                      showClose: true,
                      message: errors.message,
                      type: 'error'
                  });
              })
          }else{
              this.$message({
                  showClose: true,
                  message: '手机号或者验证码不能为空',
                  type: 'error'
              });
          }
      },
  }
}
</script>

<style scoped>
    .login {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .box {
        width: 400px;
        height: 420px;
        background-color: white;
        border-radius: 10px;
        position: relative;
        top: calc(50vh - 210px);
        left: calc(50vw - 200px);
    }

    .el-icon-close {
        position: absolute;
        font-weight: bold;
        font-size: 20px;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .el-icon-close:hover {
        color: darkred;
    }

    .content {
        position: absolute;
        top: 40px;
        width: 280px;
        left: 60px;
    }

    .nav {
        font-size: 20px;
        height: 38px;
        border-bottom: 2px solid darkgrey;
    }

    .nav > span {
        margin: 0 20px 0 35px;
        color: darkgrey;
        user-select: none;
        cursor: pointer;
        padding-bottom: 10px;
        border-bottom: 2px solid darkgrey;
    }

    .nav > span.active {
        color: black;
        border-bottom: 3px solid black;
        padding-bottom: 9px;
    }

    .el-input, .el-button {
        margin-top: 40px;
    }

    .el-button {
        width: 100%;
        font-size: 18px;
    }

    .foot > span {
        float: right;
        margin-top: 20px;
        color: orange;
        cursor: pointer;
    }

    .sms {
        color: orange;
        cursor: pointer;
        display: inline-block;
        width: 70px;
        text-align: center;
        user-select: none;
    }
</style>