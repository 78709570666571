<template>
    <div>
        <iframe src="fire.html" id="fire" name="fire" style="width: 100%;height: 800px" ref="fire"></iframe>
    </div>
</template>

<script>
    export default {
        name: "Fire",
    }

</script>

<style scoped>

</style>